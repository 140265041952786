import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svg/pointsInfo.svg'


const _withScopeId = n => (_pushScopeId("data-v-69cf461d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "pointsContainer" }
const _hoisted_2 = { id: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PointsInfo = _resolveComponent("PointsInfo")!
  const _component_NoPoints = _resolveComponent("NoPoints")!
  const _component_PointsHistoryTable = _resolveComponent("PointsHistoryTable")!
  const _component_PointsLoadingMobile = _resolveComponent("PointsLoadingMobile")!
  const _component_master_layout = _resolveComponent("master-layout")!

  return (_openBlock(), _createBlock(_component_master_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('points_record')), 1 /* TEXT */),
            _createElementVNode("img", {
              id: "infoPointsActivator",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.recordStore.setShowinfoWatiosWatCoins(true))),
              src: _imports_0,
              alt: ""
            })
          ]),
          _createVNode(_component_PointsInfo, {
            withExchangeButtom: "true",
            id: "pointsInfoContainer"
          }),
          _withDirectives(_createVNode(_component_NoPoints, null, null, 512 /* NEED_PATCH */), [
            [_vShow, _ctx.recordStore.state.recordState.userPoints.count == 0]
          ]),
          _withDirectives(_createVNode(_component_PointsHistoryTable, { id: "pointsHistoryTableContainer" }, null, 512 /* NEED_PATCH */), [
            [_vShow, _ctx.recordStore.state.recordState.userPoints.count > 0]
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, !_ctx.loading]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_PointsLoadingMobile, { id: "pointsLoadingContainer" })
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.loading]
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}